import './App.css';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import QRCode from 'qrcodejs2';

function openApp(){
  const search = window.location.search; // 获取 URL 中的查询字符串，如 "?foo=bar"
  const params = new URLSearchParams(search); // 使用 URLSearchParams 解析查询字符串
  if (navigator.userAgent.match(/(iPhone|iPod|iPad);?/i)) {
      const foo = params.get('userId'); 
      window.location = "qvchat://"+search;
      var ifr = document.createElement("iframe");
      ifr.src = "qvchat://"; /***打开app的协议，ios同事提供***/
      ifr.style.display = "none";
      document.body.appendChild(ifr);
      window.setTimeout(function(){
          document.body.removeChild(ifr);
          window.location.href = 'https://apps.apple.com/us/app/bbgchat/id6450296997'; /***下载app的地址***/
      },2000);

  } else if (navigator.userAgent.match(/android/i)) {
      //var state = null;
      try {
          const androidUrl = `intent://dl/${search}#Intent;scheme=qvchat;package=com.quantvortex.im2023;end`; //安卓兄弟可以给你
          window.location = androidUrl; //安卓兄弟可以给你
          setTimeout(function(){
              window.location= "https://www.pgyer.com/3cTbWn"; //android下载地址
          },2000);
      } catch(e) {}
  }
}

function isMobileDevice() {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
}

function App() {
  const isMobile = useMemo(() => isMobileDevice(), []);
  const $ewm = useRef(null);
  const qrcode = useRef(null);
  const [showWxTips, setShowWxTips] = useState(false);

  useEffect(() => {
    if ($ewm.current) {
      if (!qrcode.current) {
        qrcode.current = new QRCode($ewm.current, {
          text: window.location.href,
          width: 400,
          height: 400,
        });
      } else {
        qrcode.current.makeCode(window.location.href);
      }
    }
  }, []);

  const onJoin = useCallback(() => {
    const ua = window.navigator.userAgent.toLowerCase();
    //微信
    if(ua.indexOf('micromessenger') !== -1) {
      setShowWxTips(true);
    } else {
      openApp();
    }
  }, []);

  const params = new URLSearchParams(window.location.search); // 使用 URLSearchParams 解析查询字符串
  const userName = params.get('name') || 'Someone';
  const groupId = params.get('groupId');

  let inviteTips = `${userName} has invited you to start a conversation.`
  if (groupId) {
    inviteTips = `${userName} has invited you to start a group conversation.`
  }

  let contentView;
  if (isMobile) {
    contentView = (
      <div className='content-view'>
        <div>Hi:</div>
        <p>{inviteTips}</p>
        <p className='tips'>BBGChat: Business Best Group Chat</p>
        <div className='join-button' onClick={onJoin}>Join the chat</div>
      </div>
    )
  } else {
    contentView = (
      <div className='content-view-pc'>
        <div className='content-view'>
          <div>Hi:</div>
          <p>{inviteTips}</p>
          <p>To join the chat, please scan the QR code with your mobile device.</p>
          <p className='tips'>BBGChat: Business Best Group Chat</p>
        </div>
        <div className='download-item'>
          <div className='download-ewm' ref={$ewm} />
        </div>
      </div>
    )
  }
  return (
    <div className='contain'>
      <div className='bbg-header'>
        <div className='bbg-icon' />
        BBGChat
      </div>
      {contentView}
      {showWxTips && (
        <div className='wx-tips'>
            Click ... and choose to open in the browser
        </div>
      )}
    </div>
  );
}

export default App;
